/* this file is transformed by vux-loader */
/* eslint-disable */
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('rx-layout', [_c('yd-navbar', {
    attrs: {
      "slot": "navbar",
      "title": "任务会签"
    },
    slot: "navbar"
  }, [_c('a', {
    attrs: {
      "slot": "left",
      "href": "javascript:;"
    },
    on: {
      "click": _vm.goBack
    },
    slot: "left"
  }, [_c('yd-navbar-back-icon')], 1)]), _c('div', {
    attrs: {
      "slot": "center"
    },
    slot: "center"
  }, [_c('div', {
    staticClass: "formBox"
  }, [_c('h1', {
    attrs: {
      "slot": "left"
    },
    slot: "left"
  }, [_vm._v("会签人")]), _c('span', {
    attrs: {
      "slot": "left"
    },
    slot: "left"
  }, [_c('rx-boxs', {
    attrs: {
      "allowSelect": true,
      "containstyle": "width:2.2rem;",
      "single": _vm.single,
      "textField": "text",
      "valField": "id",
      "type": 'user'
    },
    on: {
      "select": _vm.selectUserDialog
    },
    model: {
      value: _vm.userJsons,
      callback: function ($$v) {
        _vm.userJsons = $$v;
      },
      expression: "userJsons"
    }
  })], 1)]), _c('div', {
    staticClass: "formBox"
  }, [_c('h1', {
    attrs: {
      "slot": "left"
    },
    slot: "left"
  }, [_vm._v("意见")]), _c('yd-textarea', {
    attrs: {
      "slot": "right",
      "placeholder": "请输入意见",
      "maxlength": "200"
    },
    slot: "right",
    model: {
      value: _vm.opinion,
      callback: function ($$v) {
        _vm.opinion = $$v;
      },
      expression: "opinion"
    }
  })], 1), _c('yd-cell-group', [_c('yd-cell-item', {
    attrs: {
      "type": "label"
    }
  }, [_c('rx-attachment', {
    attrs: {
      "slot": "left",
      "readonly": false
    },
    slot: "left",
    model: {
      value: _vm.files,
      callback: function ($$v) {
        _vm.files = $$v;
      },
      expression: "files"
    }
  })], 1)], 1), _c('div', {
    staticClass: "formBox"
  }, [_c('h1', {
    attrs: {
      "slot": "left"
    },
    slot: "left"
  }, [_vm._v("通知")]), _c('span', {
    attrs: {
      "slot": "left"
    },
    slot: "left"
  }, _vm._l(_vm.noticeType, function (item) {
    return _c('rx-check', {
      key: item.name,
      attrs: {
        "val": item.name
      },
      model: {
        value: _vm.type,
        callback: function ($$v) {
          _vm.type = $$v;
        },
        expression: "type"
      }
    }, [_vm._v(_vm._s(item.text))]);
  }), 1)])], 1), _c('ul', {
    staticClass: "navBox",
    attrs: {
      "slot": "tabbar"
    },
    slot: "tabbar"
  }, [_c('li', {
    on: {
      "click": function ($event) {
        return _vm.handCommu();
      }
    }
  }, [_c('div', [_c('svg', {
    staticClass: "icon",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c('use', {
    attrs: {
      "xlink:href": "#yd-icon-queding"
    }
  })])]), _c('div', [_vm._v("确定")])]), _c('li', {
    on: {
      "click": function ($event) {
        return _vm.goBack();
      }
    }
  }, [_c('div', [_c('svg', {
    staticClass: "icon",
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c('use', {
    attrs: {
      "xlink:href": "#yd-icon-quxiao"
    }
  })])]), _c('div', [_vm._v("取消")])])]), _c('yd-popup', {
    attrs: {
      "position": "center",
      "width": "100%"
    },
    model: {
      value: _vm.showUserDialog,
      callback: function ($$v) {
        _vm.showUserDialog = $$v;
      },
      expression: "showUserDialog"
    }
  }, [_c('rx-sysuser', {
    ref: "sysUser",
    attrs: {
      "single": _vm.single
    },
    on: {
      "ok": _vm.selectUser,
      "cancel": function ($event) {
        _vm.showUserDialog = false;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };